import { SearchIcon } from '@heroicons/react/outline';
import { useMemo } from 'react';
import Select from 'react-select';
import useTeams from '@/hooks/useTeams';

type Props = {
  onChange: (ids: string[]) => void;
  texts?: any;
  teams?: string[];
  includeFavs: boolean;
};

export const SelectTeams = ({
  onChange,
  texts = {},
  teams = [],
  includeFavs = false
}: Props) => {
  texts = {
    selectTeams: (
      <div className="flex items-center text-gray-600">
        <SearchIcon className="h-5 w-5" />
        <span className="pl-2">
          Select {includeFavs ? ' your lists or teams' : 'teams'}
        </span>
      </div>
    ),
    ...(texts || {})
  };

  const defaultValues = {
    teams: teams || []
  };

  const { teamList } = useTeams({ type: 'directory', hidden: false });
  const { teamList: groups } = useTeams({ type: 'group', hidden: false });

  const options = useMemo(() => {
    const elements = (teamList ? teamList.elements : []).concat(
      groups && includeFavs ? groups.elements : []
    );
    return elements.map((x) => ({ label: x.name, value: x.id }));
  }, [teamList, groups, includeFavs]);

  return (
    <>
      {teamList && groups ? (
        <Select
          className="w-full rounded-md border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2"
          classNamePrefix="react-select"
          isMulti
          placeholder={texts.selectTeams}
          options={options.sort((a, b) => a.label.localeCompare(b.label))}
          defaultValue={
            defaultValues.teams
              ? options.filter((option: { value: string }) =>
                  defaultValues.teams.includes(option.value)
                )
              : []
          }
          onChange={(value) => {
            const teamIds = value.map((option) => option.value);
            onChange(teamIds);
          }}
        />
      ) : null}
    </>
  );
};
