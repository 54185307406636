import { format } from 'date-fns';
import Link from 'next/link';
import { useAuthenticated } from '@/lib/api/appUser';
import { isHealthModuleRestrictionsEnabled } from '@/lib/featureFlags';

export function EmployeeHealthBanner() {
  const {
    userContext: { currentOffice },
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const userHealth = authenticatedUser?.user?.organization_user.health;
  const healthModuleRestrictionsEnabled = isHealthModuleRestrictionsEnabled(
    authenticatedUser,
    currentOffice
  );
  // If health restrictions are turned on, unknown and pending users will need to upload documents
  // Partially cleared users will need to see the banner until they are in a clear + end or partial + end
  const bannerShouldDisplayAlways = ['unknown', 'pending'];
  const partiallyCleared =
    userHealth?.status?.includes('partial') && !userHealth.end;
  // Separate risk status to show end of date for temporary
  const userTemporaryRisk =
    userHealth?.status?.includes('risk') && userHealth?.end;
  const userRisk = userHealth?.status?.includes('risk') && !userHealth?.end;
  // User cleared is all versions since we don't want to show a banner here
  const userCleared =
    (userHealth?.status?.includes('partial') && userHealth?.end) ||
    userHealth?.status?.includes('clear');

  // If the organization has the health module turned on but restrictions are not yet enabled, don't show banner to employees
  if (!healthModuleRestrictionsEnabled) return <></>;
  if (userCleared) return <></>;

  // User is risk, show banner telling them they won't be able to be in-office. We don't say anything about
  // making reservations since we don't know the office setting.
  // TODO: Update language with more specificifity once we have dynamic office setting values.
  if (userTemporaryRisk || userRisk)
    return (
      <div>
        <div className="mt-5 flex w-full flex-col items-center justify-around rounded-lg bg-gray-600 p-10 sm:flex-row">
          <h4 className="text-center text-xl text-white sm:text-left">
            Your organization's health policies currently prevent you from
            in-office attendance.{' '}
            {userTemporaryRisk && userHealth.end ? (
              <span>
                You will be able to be in-office after:{' '}
                {format(
                  new Date(userHealth?.end?.replace('Z', '')),
                  'MM/dd/yy'
                )}
              </span>
            ) : null}
          </h4>
        </div>
      </div>
    );

  // TODO: Update language with more specificifity once we have dynamic office setting values.
  if (bannerShouldDisplayAlways || partiallyCleared)
    return (
      <div>
        <div className="mt-5 flex w-full flex-col items-center justify-around rounded-lg bg-gray-600 p-10 sm:flex-row">
          <h4 className="max-w-md text-center  text-xl text-white sm:text-left">
            Your office requires health documents to be uploaded prior to
            in-office attendance.{' '}
          </h4>
          <Link href="/settings/health">
            <a className="mt-5 rounded-full bg-gray-50 px-16 py-2.5 text-lg font-semibold text-gray-600 sm:mt-0  md:px-10">
              Get started
            </a>
          </Link>
        </div>
      </div>
    );

  return <></>;
}
