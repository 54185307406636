import React, { useMemo } from 'react';
import Select, { components } from 'react-select';
import { classNames } from '@/lib/classNames';

type Props = {
  value: string;
  setValue: (x: string) => void;
  options: { label: string; value: string; available: number | null }[];
};

export default function OOOSelector({ value, setValue, options }: Props) {
  const option = useMemo(() => {
    const ret = options.find((x) => x.value == value);
    return ret;
  }, [options, value]);

  const AvailableLabel = ({ available }: { available: number | null }) => (
    <span
      className={classNames(
        available === null || available >= 1
          ? 'bg-green-100 text-green-800'
          : 'bg-red-100 text-red-800',
        'ml-2 rounded-full  px-2.5 py-0.5 font-medium '
      )}
    >
      {available === null ? 'unlimited' : `${available} available`}
    </span>
  );

  return (
    <Select
      components={{
        SingleValue: ({ children, ...props }) => (
          <components.SingleValue {...props}>
            <span className="capitalize">{children}</span>{' '}
            <AvailableLabel available={props.data.available} />
          </components.SingleValue>
        ),
        Option: (props) => (
          <components.Option {...props}>
            <span className="capitalize">{props.data.label}</span>
            <AvailableLabel available={props.data.available} />
          </components.Option>
        )
      }}
      isOptionDisabled={(option) =>
        option.available !== null && option.available <= 0
      }
      className={''}
      onChange={(d, event) => {
        setValue(d?.value ?? '');
      }}
      value={option}
      options={options}
      classNamePrefix={'react-select'}
      placeholder="Select type..."
    />
  );
}
