import { Switch } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useState } from 'react';
import { IoCalendarOutline as CalIcon } from 'react-icons/io5';
import { ScheduleParamsAll } from '@gettactic/api';
import { api } from '@/lib/api/api';
import { useAuthenticated } from '@/lib/api/appUser';
import { classNames } from '@/lib/classNames';
import {
  getCustomOfficeDashboardMessage,
  isHealthAdmin,
  isHealthModuleEnabled
} from '@/lib/featureFlags';
import { canRespondOthers, canScheduleOthers } from '@/lib/permissions';
import { Tooltip } from '@/components/basic';
import CheckedInTeamMembers from '@/components/partial/CheckedInTeamMembers';
import { NewUserWelcomeModal } from '@/components/partial/Modals/NewUserWelcomeModal';
import WeeklyScrollReservations from '@/components/partial/WeeklyScrollReservations';
import LoadingSpinner from '@/components/util/LoadingSpinner';
import { CustomOfficeDashboardBanner } from '@/components/dashboard/CustomOfficeDashboardBanner';
import Reservations from '@/components/dashboard/Reservations';
import ReservationsMobilePanel from '@/components/dashboard/ReservationsMobilePanel';
import { EmployeeHealthBanner } from '@/components/health/EmployeeHealthBanner';
import { BannerPendingDocument } from '@/components/health/admin/BannerPendingDocument';

export default function OldDashboard() {
  const {
    userContext: { currentOffice, authenticatedUser }
  } = useAuthenticated();
  const [weekDate, setWeekDate] = useState<string>(() =>
    format(new Date(), 'yyyy-MM-dd')
  );
  const [openSidePanel, setOpenSidePanel] = useState(false);

  const [percentageEnabled, setPercentageEnabled] = useState(false);
  const canApprove = canRespondOthers(authenticatedUser);

  const questionnaireData = useQuery(
    ['organizations.questionnaire', currentOffice.id],
    async () => {
      const res = await api.client.organizations.questionnaire(
        currentOffice.id
      );
      return res.result;
    },
    {
      keepPreviousData: true,
      enabled: !!currentOffice.id
    }
  );

  const commonParams: ScheduleParamsAll = {
    date: weekDate,
    weeks: 1,
    office: [currentOffice.id],
    type: ['desk', 'workspace', 'meeting_room', 'parking_space', 'parking_lot'],
    tz: currentOffice.time_zone
  };

  const scheduleMeData = useQuery(
    ['schedules', 'me', commonParams],
    async () => {
      const res = await api.client.schedules.allIndexed('me', commonParams);
      return res.result;
    },
    {
      keepPreviousData: true,
      enabled: !!currentOffice.id
    }
  );
  const usageData = useQuery(
    ['schedules', 'usageOccupied', commonParams],
    async () => {
      const res = await api.client.schedules.allUsage('occupied', commonParams);
      return res.result ? api.client.schedules.formatUsages(res.result) : null;
    },
    {
      keepPreviousData: true,
      enabled: !!currentOffice.id
    }
  );
  const scheduleOccupiedData = useQuery(
    ['schedules', 'occupied', commonParams],
    async () => {
      const res = await api.client.schedules.allIndexed(
        'occupied',
        commonParams
      );
      return res.result;
    },
    {
      keepPreviousData: true,
      enabled: !!currentOffice.id
    }
  );

  const hasScheduler = canScheduleOthers(authenticatedUser);
  const schedulerData = useQuery(
    ['schedules', 'managed', commonParams],
    async () => {
      const res = await api.client.schedules.allIndexed(
        'managed',
        commonParams
      );
      return res.result;
    },
    {
      keepPreviousData: true,
      enabled: !!currentOffice?.id && hasScheduler
    }
  );
  const schedulePendingData = useQuery(
    ['schedules', 'pending', commonParams],
    async () => {
      const res = await api.client.schedules.allIndexed(
        'pending',
        commonParams
      );
      return res.result;
    },
    {
      keepPreviousData: true,
      enabled: !!currentOffice?.id && !!canApprove
    }
  );

  return (
    <>
      {!usageData.data ? (
        <div className="flex h-full items-center justify-center text-secondary md:overflow-x-auto">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="flex h-full text-secondary md:overflow-x-auto">
          <main className="px-4 py-2 focus:outline-none sm:px-6 md:flex-1 lg:px-8">
            <div className={'mx-auto max-w-[1000px]'}>
              {isHealthModuleEnabled(authenticatedUser) &&
              isHealthAdmin(authenticatedUser) ? (
                <BannerPendingDocument />
              ) : null}
              {isHealthModuleEnabled(authenticatedUser) &&
              !isHealthAdmin(authenticatedUser) ? (
                <EmployeeHealthBanner />
              ) : null}
              {currentOffice &&
              currentOffice.id &&
              getCustomOfficeDashboardMessage(currentOffice.id) ? (
                <CustomOfficeDashboardBanner office={currentOffice} />
              ) : null}

              <div className="mx-2 mt-2 mb-12 flex h-14 items-center justify-between">
                <NewUserWelcomeModal authenticatedUser={authenticatedUser} />
                {usageData.isSuccess && usageData.data ? (
                  <h2 className="text-3xl font-bold text-secondary">
                    {usageData.data.month}
                  </h2>
                ) : (
                  <div className="h-[45px] w-1/2 animate-pulse rounded-md bg-gray-300" />
                )}
                <div className="flex items-center justify-end">
                  {usageData ? (
                    <>
                      <div className="flex items-center">
                        <span className="px-2">Reservations</span>
                        <Switch
                          checked={percentageEnabled}
                          onChange={setPercentageEnabled}
                          className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2"
                        >
                          <span className="sr-only">Use setting</span>
                          <span
                            aria-hidden="true"
                            className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                          />
                          <span
                            aria-hidden="true"
                            className={classNames(
                              percentageEnabled ? 'bg-primary' : 'bg-gray-200',
                              'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                            )}
                          />
                          <span
                            aria-hidden="true"
                            className={classNames(
                              percentageEnabled
                                ? 'translate-x-5'
                                : 'translate-x-0',
                              'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                            )}
                          />
                        </Switch>
                        <span className="px-2">Capacity</span>
                      </div>
                      <Tooltip position="top" width={400}>
                        Reservations shows the total number of workspace
                        reservations on a given day. Capacity shows the total
                        number of available workspaces remaining, formatted as
                        total percentage reserved on hover.
                      </Tooltip>
                    </>
                  ) : null}
                  <div className="ml-6 xl:hidden">
                    <button
                      className="mt-2 flex items-center justify-center border-b border-blue-800 pb-2 font-bold text-secondary"
                      onClick={() => {
                        setOpenSidePanel((x) => !x);
                      }}
                    >
                      <CalIcon className="mr-1" />
                      <span>My reservations</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-6 flex">
                {currentOffice &&
                usageData.isSuccess &&
                scheduleMeData.isSuccess ? (
                  <WeeklyScrollReservations
                    usageData={usageData.data}
                    scheduleMeData={scheduleMeData.data}
                    office={currentOffice}
                    setWeekDate={setWeekDate}
                    capacity={percentageEnabled}
                  />
                ) : (
                  <div className="h-[163px] w-full animate-pulse rounded-md bg-gray-300" />
                )}
              </div>
              <div className="flex flex-col">
                {currentOffice.id && currentOffice && (
                  <CheckedInTeamMembers weekDate={weekDate} />
                )}
              </div>
            </div>
          </main>
          {currentOffice.id &&
          questionnaireData.isSuccess &&
          questionnaireData.data &&
          scheduleMeData.isSuccess &&
          scheduleMeData.data &&
          // we require the schedulerData only if user has the correct perms
          (!hasScheduler || (schedulerData.isSuccess && schedulerData.data)) &&
          (!canApprove ||
            (schedulePendingData.isSuccess && schedulePendingData.data)) &&
          scheduleOccupiedData.isSuccess &&
          scheduleOccupiedData.data ? (
            <>
              <ReservationsMobilePanel
                weekDate={weekDate}
                setWeekDate={setWeekDate}
                setOpen={setOpenSidePanel}
                open={openSidePanel}
                user={authenticatedUser}
                officeId={currentOffice.id}
                questionnaireData={questionnaireData.data}
                scheduleMeData={scheduleMeData.data}
                schedulerData={schedulerData.data ?? null}
                schedulePendingData={schedulePendingData.data ?? null}
                canApprove={!!canApprove}
                scheduleOccupiedData={scheduleOccupiedData.data}
              />

              <aside className="hidden w-156 shrink-0 border-l border-gray-200 bg-white xl:flex xl:flex-col">
                <Reservations
                  weekDate={weekDate}
                  setWeekDate={setWeekDate}
                  user={authenticatedUser}
                  officeId={currentOffice.id}
                  questionnaireData={questionnaireData.data}
                  scheduleMeData={scheduleMeData.data}
                  schedulerData={schedulerData.data ?? null}
                  schedulePendingData={schedulePendingData.data ?? null}
                  canApprove={!!canApprove}
                  scheduleOccupiedData={scheduleOccupiedData.data}
                />
              </aside>
            </>
          ) : null}
        </div>
      )}
    </>
  );
}
