import { IUserFull } from '@gettactic/api';
import useFavs from '@/hooks/useFavs';
import { FavsDetails } from '@/components/favs/FavsDetails';
import FavsOpen from '@/components/favs/FavsOpen';

type Props = {
  user: IUserFull;
  size: 'sm' | 'md' | 'lg';
};

const SIZES = {
  sm: 'w-4 h-4 ',
  md: 'w-8 h-8 ',
  lg: 'w-16 h-16 '
};

export function Favs({ user, size = 'sm' }: Props) {
  const {
    open,
    setOpen,
    styles,
    attributes,
    setArrowElement,
    setPopperElement,
    referenceElement,
    setReferenceElement,
    hasAnyFav,
    removeUserFromDefaultGroup,
    addUserToDefaultGroup,
    addUserToGroup,
    removeUserFromGroup,
    groups,
    userGroups
  } = useFavs(user);

  return (
    <>
      <FavsDetails
        open={open}
        setArrowElement={setArrowElement}
        setOpen={setOpen}
        setPopperElement={setPopperElement}
        styles={styles}
        attributes={attributes}
        referenceElement={referenceElement}
        hasAnyFav={hasAnyFav}
        removeUserFromDefaultGroup={removeUserFromDefaultGroup}
        addUserToDefaultGroup={addUserToDefaultGroup}
        addUserToGroup={addUserToGroup}
        removeUserFromGroup={removeUserFromGroup}
        groups={groups ?? undefined}
        userGroups={userGroups ?? undefined}
      />
      <FavsOpen
        title={
          !hasAnyFav
            ? `Add ${user.name} to your lists`
            : `${user.name} is added to your lists`
        }
        sizeClasses={SIZES[size]}
        hasAnyFav={hasAnyFav}
        setOpen={setOpen}
        open={open}
        setReferenceElement={setReferenceElement}
      />
    </>
  );
}
