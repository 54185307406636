import { ScrollArea } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import {
  ISchedule,
  IUserFull,
  Office,
  ScheduleParamsAll
} from '@gettactic/api';
import { api } from '@/lib/api/api';
import { useAuthenticated } from '@/lib/api/appUser';
import { classNames } from '@/lib/classNames';
import { isAllowedToReserve, parseApiDate } from '@/lib/utils';
import { Tooltip } from '@/components/basic';
import { Favs } from '@/components/favs/Favs';
import { ReserveModal } from '@/components/reserve/Reserve/ReserveModal';
import { USER_PHOTO_SIZES, UserPhotoCard } from './Cards/UserPhotoCard';
import { SelectTeams } from './SelectTeams';

const ListItem = ({
  days,
  user,
  slots,
  office
}: {
  days: { [p: string]: number };
  user: IUserFull;
  slots: {
    [dateIdx: string]: ISchedule[];
  };
  office: Office | undefined;
}): JSX.Element => {
  const { setUserContext } = useAuthenticated();
  const [isReserveModalOpen, setIsReserveModalOpen] = useState(false);
  // Show all resource names reserved on a specific day on the title hover
  function getResourceNames(date: string) {
    if (slots.hasOwnProperty(date)) {
      return slots[date]
        .map((x) => x.resource.name)
        .filter((x) => x.length)
        .join(', ');
    } else {
      return 'No reservations';
    }
  }
  return (
    <li className="border-b border-gray-200" key={user.id}>
      <div className="block transition duration-150 ease-in-out hover:bg-gray-50 focus:bg-gray-50 focus:outline-none">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="flex min-w-0 flex-1 items-center">
            <div className="flex-shrink-0">
              <UserPhotoCard
                size={USER_PHOTO_SIZES.ROUNDED_12_12}
                name={user.name}
                profile_photo_url={
                  user.profile_photo_url ? user.profile_photo_url : null
                }
              />
            </div>
            <div className="min-w-0 flex-1 px-4">
              <div className="flex flex-col truncate text-base">
                <p className="flex items-center space-x-1 font-bold leading-5 text-gray-800">
                  <span>{user.name}</span>
                  <Favs size="sm" user={user} />
                </p>
                <p className="text-gray-600">{user.title}</p>
              </div>
            </div>
            <div className="flex justify-around">
              {Object.keys(days).map((date) => {
                // Checks the array of objects for date matches
                // if it finds a match, sets styling for active
                const activeBooking = slots.hasOwnProperty(date);
                const startDate = parseApiDate(date);
                const allowed = isAllowedToReserve(
                  startDate,
                  office?.time_zone ?? '',
                  office,
                  'workspace'
                );
                const reserve = () => {
                  if (!allowed) {
                    return;
                  }
                  setIsReserveModalOpen(true);
                };
                return (
                  <>
                    <button
                      key={date}
                      aria-label={date}
                      className={classNames(
                        'mr-1 h-8 w-8 self-center rounded-full p-0 text-base font-bold shadow-sm focus:outline-none focus:outline-none focus-visible:ring-2 focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 focus-visible:ring-offset-2',
                        !activeBooking && 'border border-gray-300',
                        activeBooking && 'border border-primary text-primary',
                        allowed && !activeBooking && 'bg-white text-gray-600',
                        !allowed && 'cursor-not-allowed bg-white text-gray-400'
                      )}
                      title={getResourceNames(date)}
                      onClick={reserve}
                    >
                      {format(parseISO(date), 'EEEEE')}
                    </button>
                    <ReserveModal
                      reserveModal={isReserveModalOpen}
                      resourceType="desk"
                      embedded={false}
                      start={startDate}
                      onClose={() => setIsReserveModalOpen(!isReserveModalOpen)}
                    />
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

const CheckedInTeamMembers = ({ weekDate }: { weekDate: string }) => {
  const {
    userContext: { authenticatedUser },
    userContext: { currentOffice }
  } = useAuthenticated();
  const lastGroupUsed =
    authenticatedUser?.user?.organization_user.last_team_group_id;
  const [teams, setTeams] = useState<string[]>(
    lastGroupUsed ? [lastGroupUsed] : []
  );
  const params: ScheduleParamsAll = {
    date: weekDate,
    office: [currentOffice?.id],
    team: teams,
    tz: currentOffice.time_zone
  };
  const colleagues = useQuery(
    ['schedules', 'occupied', params],
    async () => {
      const res = await api.client.schedules.allIndexed('occupied', params);
      return res.result;
    },
    {
      enabled: !!currentOffice.id
    }
  );

  // Alphabetical sort
  const sortedUsers = Object.values(colleagues?.data?.users ?? []).sort(
    (a, b) => a.name.localeCompare(b.name)
  );

  return (
    <div className="rounded-lg bg-white text-secondary shadow">
      <div className="rounded-t-lg border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <div className="flex flex-1 justify-start px-4">
          <div className="flex flex-1 items-center">
            <h4 className="text-lg font-bold leading-6 text-gray-800">
              Team Schedules
            </h4>
            <Tooltip position="top">
              <span className="text-base font-normal text-gray-800">
                Team Schedules is where you&apos;re able to see when the rest of
                your team will be in-office. Use the dropdown to select specific
                teams.
              </span>
            </Tooltip>
          </div>
          <div className="ml-4 flex flex-1 items-end md:ml-6">
            <SelectTeams
              includeFavs={true}
              teams={teams}
              onChange={(teamIds: string[]) => setTeams(teamIds)}
            />
          </div>
        </div>
      </div>
      {currentOffice && colleagues && colleagues.data ? (
        <>
          {sortedUsers.length > 0 ? (
            <ScrollArea
              style={{ height: 'calc(100vh - 475px)' }}
              offsetScrollbars
              className="h-full"
            >
              <ul>
                {sortedUsers
                  .filter((x) => !x.roles.includes('deleted'))
                  .map((user) => (
                    <ListItem
                      key={user.id}
                      user={user}
                      days={colleagues!.data!.days}
                      slots={colleagues.data!.dates_user[user.id]}
                      office={currentOffice}
                    />
                  ))}
              </ul>
            </ScrollArea>
          ) : (
            <div className="mx-auto flex max-w-3xl flex-col justify-center p-6 text-center">
              <h3 className="text-lg font-bold text-gray-800">
                Hmm. Looks like there aren't any team reservations yet this
                week.
              </h3>
              <p className="mt-2 text-gray-800">
                Once you're in a team and they are reserving workspaces in the
                office, you'll see their schedules here. Maybe try a different
                week by using the arrows above.
              </p>
            </div>
          )}
        </>
      ) : (
        <div className="h-[163px] w-full animate-pulse rounded-md bg-gray-400" />
      )}
    </div>
  );
};

export default CheckedInTeamMembers;
