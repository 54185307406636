import { BookmarkIcon as BookmarkIconSolid } from '@heroicons/react/solid';
import React, { Dispatch, SetStateAction } from 'react';
import { classNames } from '@/lib/classNames';

type Props = {
  title: string;
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  setReferenceElement: Dispatch<SetStateAction<HTMLElement | null>>;
  hasAnyFav: boolean;
  sizeClasses: string;
};

export default function FavsOpen({
  title,
  setOpen,
  setReferenceElement,
  hasAnyFav,
  sizeClasses = 'w-4 h-4 '
}: Props) {
  return (
    <button
      title={title}
      ref={setReferenceElement}
      onClick={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setOpen((x) => !x);
      }}
      type="button"
    >
      <BookmarkIconSolid
        className={classNames(
          sizeClasses,
          'transition duration-150 ease-in-out hover:text-primary-hover',
          !hasAnyFav ? 'text-gray-200' : 'text-primary'
        )}
      />
    </button>
  );
}
