export const isJSON = (str: string | null) => {
  try {
    if (!str) return false;
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const initStorage = (storeName: string) => {
  if (
    localStorage &&
    (!localStorage.getItem(storeName) ||
      !isJSON(localStorage.getItem(storeName)))
  ) {
    localStorage.setItem(storeName, JSON.stringify({}));
  }
};

export const setItem = (storeName: string, key: string, data: any) => {
  const store = localStorage.getItem(storeName);
  if (store && isJSON(store)) {
    const parsedStore = JSON.parse(store);
    parsedStore[key] = data;
    localStorage.setItem(storeName, JSON.stringify(parsedStore));
  } else {
    initStorage(storeName);
    const parsedStore = {};
    parsedStore[key] = data;
    localStorage.setItem(storeName, JSON.stringify(parsedStore));
  }
};

export function getItem<T>(storeName: string, key: string): T | null {
  const store = localStorage.getItem(storeName);
  if (store && isJSON(store)) {
    const parsedStore = JSON.parse(store);
    return parsedStore[key] as T;
  }
  return null;
}

export const getStringifiedItem = (storeName: string, key: string): string => {
  const store = localStorage.getItem(storeName);
  if (store && isJSON(store)) {
    const parsedStore = JSON.parse(store);
    return JSON.stringify(parsedStore[key]);
  }
  return '';
};

export const removeItem = (storeName: string, key: string) => {
  const store = localStorage.getItem(storeName);
  if (store && isJSON(store)) {
    const parsedStore = JSON.parse(store);
    if (parsedStore[key]) {
      delete parsedStore[key];
      localStorage.setItem(storeName, JSON.stringify(parsedStore));
    }
  }
};

export const clearStorage = (storeName: string) => {
  const store = localStorage.getItem(storeName);
  if (store) {
    localStorage.removeItem(storeName);
  }
};
