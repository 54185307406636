import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { ITeam, ITeamType, IUserFull } from '@gettactic/api';
import { api } from '@/lib/api/api';
import useTeams from '@/hooks/useTeams';
import useToggle from '@/hooks/useToggle';

export function useUserTeamsList(userId: string | null, type: ITeamType) {
  const {
    data: userTeamList,
    error,
    isLoading
  } = useQuery(
    ['teams.forUser', type, userId],
    async () => {
      if (!userId) throw new Error();
      const res = await api.client.teams.forUser({ userId, type });
      return res.result;
    },
    {
      enabled: !!userId
    }
  );
  return {
    userTeamList,
    error,
    isLoading
  };
}

export default function useUserTeams(user: IUserFull, initialList: ITeam[]) {
  const [teamMemberList, setTeamMemberList] = useState(initialList);

  const { teamList } = useTeams({ type: 'directory', hidden: false });

  const [displayOnlyAssigned, toggleDisplayOnlyAssigned] = useToggle(
    initialList.length > 0
  );
  const [teamsQuery, setTeamsQuery] = useState('');

  function isMember(team: ITeam) {
    return teamMemberList.find((x) => x.id === team.id);
  }
  function addMember(team: ITeam) {
    if (isMember(team)) {
      return;
    }
    setTeamMemberList((x) => x.concat([team]));
  }
  function removeMember(team: ITeam) {
    setTeamMemberList((x) => x.filter((x) => x.id !== team.id));
  }

  return {
    displayOnlyAssigned,
    toggleDisplayOnlyAssigned,
    teamsQuery,
    setTeamsQuery,
    teamList,
    teamMemberList,
    isMember,
    addMember,
    removeMember
  };
}
