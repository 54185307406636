import { useInfiniteQuery } from '@tanstack/react-query';
import { ScheduleParamsAll } from '@gettactic/api';
import { api } from '@/lib/api/api';

export type QueryOptions =
  | { keepPreviousData?: boolean; enabled?: boolean }
  | undefined;

export function useWorkingLocations(
  filters: ScheduleParamsAll,
  options: QueryOptions = { keepPreviousData: true, enabled: true }
) {
  if (!filters.limit) {
    filters.limit = 100;
  }
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status
  } = useInfiniteQuery(
    ['schedules', 'workingLocations', filters],
    async ({ pageParam: cursor }) => {
      if (cursor) {
        filters.cursor = cursor;
      }
      const res = await api.client.schedules.workingLocations(filters);
      return res.result;
    },
    {
      getNextPageParam: (lastPage) => {
        const all = lastPage?.days ?? {};
        const days = Object.keys(all);
        let maxLength = 0;
        const lastCursor = lastPage?.cursor;
        for (let i = 0; i < days.length; i++) {
          const day = days[i];
          const dayLength = all[day].length ?? 0;
          if (dayLength > maxLength) {
            maxLength = dayLength;
          }
        }
        return maxLength < (filters.limit ?? 0)
          ? undefined
          : lastCursor ?? undefined;
      }
    }
  );

  return {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status
  };
}
