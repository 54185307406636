import { Dispatch, SetStateAction, useState } from 'react';
import { IoArrowBack, IoArrowForward, IoCheckmark } from 'react-icons/io5';
import {
  IOfficeUsageFormatted,
  IOfficesSchedulesIndexed,
  Office
} from '@gettactic/api';
import { classNames } from '@/lib/classNames';
import { isAllowedToReserve, parseApiDate } from '@/lib/utils';
import { Card } from '@/components/basic';
import { ReserveModal } from '@/components/reserve/Reserve/ReserveModal';

const WEEK_DAYS = {
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun'
};

type Props = {
  usageData: IOfficeUsageFormatted | null;
  scheduleMeData: IOfficesSchedulesIndexed | null;
  office: Office;
  setWeekDate: Dispatch<SetStateAction<string>>;
  capacity: boolean;
};

const WeeklyScrollReservations = ({
  usageData,
  scheduleMeData,
  office,
  setWeekDate,
  capacity
}: Props) => {
  const [isReserveModalOpen, setIsReserveModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const artificialConstraint: number | null = office.maximum_capacity;

  return (
    <>
      <Card.Container className="h-40 w-full" overflow="visible">
        <Card.Body>
          <div className="flex justify-around">
            {usageData && scheduleMeData ? (
              <>
                <div className="flex items-center">
                  <button
                    onClick={() => setWeekDate(usageData.previousWeekDate)}
                    type="button"
                  >
                    <div className="group inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-300 hover:bg-primary">
                      <IoArrowBack className="h-6 w-6 text-gray-800 group-hover:text-white" />
                    </div>
                  </button>
                </div>
                {usageData.usages.map((usage) => {
                  const total =
                    artificialConstraint !== null &&
                    artificialConstraint < usage.total
                      ? artificialConstraint
                      : usage.total;
                  const hasDeskReserved =
                    scheduleMeData.dates[usage.date] &&
                    scheduleMeData.dates[usage.date].find(
                      (x) =>
                        x.resource.type === 'workspace' ||
                        x.resource.type === 'desk'
                    );
                  const occupancyPercent = `${((usage.accepted / total) * 100).toFixed(0)}%`;
                  const startDate = parseApiDate(usage.date);
                  const allowed =
                    isAllowedToReserve(
                      startDate,
                      office.time_zone,
                      office,
                      'workspace'
                    ) && !hasDeskReserved;

                  const reserve = () => {
                    if (!allowed) {
                      return;
                    }
                    setStartDate(startDate);
                    setIsReserveModalOpen(true);
                  };

                  return (
                    <div
                      className="flex w-16 flex-col items-center"
                      key={usage.day}
                    >
                      <h3 className="mb-4 -mt-12 text-base text-gray-800">
                        {WEEK_DAYS[usageData.days[usage.date]] + ' '}
                      </h3>
                      <div className="flex h-10 w-10 items-center justify-center">
                        <h3 className="text-2xl font-normal text-gray-800">
                          {parseInt(usage.day, 10)}
                        </h3>
                      </div>
                      <div>
                        {hasDeskReserved && !capacity ? (
                          <>
                            <div className="my-2 flex h-12 flex-col items-center justify-center text-base">
                              <div className="group flex h-10 w-10 items-center justify-center rounded-full bg-green-600">
                                <span className="hidden text-sm text-white group-hover:flex">
                                  {capacity ? occupancyPercent : usage.accepted}
                                </span>
                                <IoCheckmark
                                  className="h-6 w-6 text-white group-hover:hidden"
                                  title="successful reservation"
                                />{' '}
                              </div>
                            </div>
                            <span className="text-gray-800">Reserved</span>
                          </>
                        ) : (
                          <>
                            <div className="group my-2 flex h-12 flex-col items-center justify-center text-base text-gray-800">
                              {capacity ? (
                                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 font-bold outline-none">
                                  <span
                                    title="Capacity reserved"
                                    className="hidden group-hover:flex"
                                  >
                                    {occupancyPercent}
                                  </span>
                                  <span
                                    title="Workspaces remaining"
                                    className="group-hover:hidden"
                                  >
                                    {total - usage.accepted}
                                  </span>
                                </div>
                              ) : (
                                <button
                                  onClick={reserve}
                                  disabled={!allowed}
                                  className={classNames(
                                    'flex h-10 w-10 items-center justify-center rounded-full outline-none',
                                    allowed && 'bg-gray-200 font-bold',
                                    !allowed &&
                                      'cursor-not-allowed bg-gray-100 text-gray-500'
                                  )}
                                >
                                  <span>
                                    {capacity
                                      ? occupancyPercent
                                      : usage.accepted}
                                  </span>
                                </button>
                              )}
                            </div>
                            <span
                              className="text-gray-800"
                              title="Number of desks remaining"
                            >
                              Desks
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
                <div className="flex items-center">
                  <button
                    onClick={() => setWeekDate(usageData.nextWeekDate)}
                    type="button"
                  >
                    <div className="group inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-300 hover:bg-primary">
                      <IoArrowForward className="h-6 w-6 text-gray-800 group-hover:text-white" />
                    </div>
                  </button>
                </div>
              </>
            ) : null}
          </div>
        </Card.Body>
      </Card.Container>
      <ReserveModal
        reserveModal={isReserveModalOpen}
        resourceType="desk"
        embedded={false}
        start={startDate}
        onClose={() => setIsReserveModalOpen(!isReserveModalOpen)}
      />
    </>
  );
};

export default WeeklyScrollReservations;
