import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-hot-toast';
import { api } from '@/lib/api/api';
import { useAuthenticated } from '@/lib/api/appUser';
import { TacticCropModal } from '@/components/upload/TacticCropModal';
import { doUpload, getCropUrl } from '@/components/upload/utils';

type InjectedProps = {
  loading: boolean;
  isDragActive: boolean;
};

export enum PhotoUploadType {
  OrganizationIcon,
  UserProfile
}

type Props = {
  type: PhotoUploadType;
  extraParams?: { [param: string]: string };
  children(props: InjectedProps): JSX.Element | null;
  resizeImage?: { w: number; h: number } | null;
  aspect?: number | null;
};

async function createObjectURL(file: File) {
  let object = '';
  if (file.name.toLocaleLowerCase().endsWith('.svg')) {
    throw new Error('SVG is not a valid format');
  }
  object = URL.createObjectURL(file);
  return new Promise<string>((resolve, reject) => {
    const img = new Image();
    img.src = object;
    img.onload = () => {
      resolve(object);
    };
    img.onerror = () => {
      reject('Not a valid image');
      URL.revokeObjectURL(object);
    };
  });
}

export default function TacticPhotoUpload({
  type,
  extraParams = {},
  children,
  aspect = null,
  resizeImage = null
}: Props) {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const onDrop = useCallback(async ([file]: any) => {
    try {
      const res = await createObjectURL(file);
      setSrc(res);
      setFile(file);
      setOpen(true);
    } catch (e) {
      toast.error('The file is not a valid image.');
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    maxFiles: 1,
    accept: 'image/*'
  });
  extraParams = {
    organizationId: authenticatedUser.organization?.id ?? '',
    ...extraParams
  };

  const uploadMutation = useMutation(
    async (photo: { url: string; type: PhotoUploadType }) => {
      if (!photo.url) {
        throw new Error('Need a valid URL for upload');
      }
      if (photo.type === PhotoUploadType.OrganizationIcon) {
        const res = await api.client.organizations.updateIcon({
          logo_url: photo.url
        });
        return { res, photo };
      } else if (photo.type === PhotoUploadType.UserProfile) {
        const res = await api.client.organizations.updatePhoto({
          profile_photo_url: photo.url
        });
        return { res, photo };
      }
      throw new Error('Something went wrong in the photo upload');
    },
    {
      onSuccess: (result, variables) => {
        setLoading(false);
        if (variables.type === PhotoUploadType.OrganizationIcon) {
          queryClient.invalidateQueries(['organizations.get']);
        } else if (variables.type === PhotoUploadType.UserProfile) {
          queryClient.invalidateQueries(['organizations.getOrgMe']);
        }
      },
      onError: (error) => {
        if (error instanceof Error) {
          console.error(error);
          throw new Error('Something went wrong with the upload');
        }
        throw new Error('Something went wrong with the upload');
      }
    }
  );

  return (
    <>
      <div className="" {...getRootProps()}>
        <input {...getInputProps()} />
        <div>{children({ loading, isDragActive })}</div>
      </div>

      <TacticCropModal
        open={open}
        setOpen={setOpen}
        src={src}
        key={src}
        aspect={aspect}
        confirmCrop={async (crop, size) => {
          if (!file) {
            throw new Error('Error trying to upload');
          }
          setLoading(true);
          try {
            let apiEndPoint = '';
            if (type === PhotoUploadType.OrganizationIcon) {
              apiEndPoint = '/api/uploads/organization';
            }
            if (type === PhotoUploadType.UserProfile) {
              apiEndPoint = '/api/uploads/photo';
            }
            const data = await doUpload(file, apiEndPoint, extraParams);
            const fullUrl = `https://cdn.gettactic.com/${data.key}`;
            let transformUrl = null;
            if (resizeImage && !crop && size) {
              transformUrl = getCropUrl(
                {
                  height: 100,
                  width: 100,
                  x: 0,
                  y: 0,
                  unit: '%',
                  aspect: 1
                },
                size,
                resizeImage,
                fullUrl
              );
            } else if (crop && size) {
              transformUrl = getCropUrl(crop, size, resizeImage, fullUrl);
            }
            uploadMutation.mutate({
              url: transformUrl ? transformUrl : fullUrl,
              type: type
            });
          } catch (e) {
            setLoading(false);
            throw e;
          }
        }}
      />
    </>
  );
}
