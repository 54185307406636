import { useEffect, useState } from 'react';

/**
 * Helper hook to set a control flag
 * persisted to localStorage and decide to
 * show something only once time per localStorage
 * session.
 */
export default function useAlreadySeen(userKey: string) {
  const [seen, setSeen] = useState(true);
  const key = `already_seen:${userKey}`;
  useEffect(() => {
    const storage = localStorage.getItem(key);
    if (!storage) {
      setSeen(false);
      localStorage.setItem(key, '1');
    }
  }, []);
  return seen;
}
