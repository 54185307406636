import { ISchedule } from '@gettactic/api';
import { useMemo } from 'react';

export function sortSchedulesByStart(a: ISchedule, b: ISchedule) {
  return a.start.localeCompare(b.start);
}

export function useSortedSchedules(
  data: ISchedule[] | undefined | null,
  filter: 'rooms' | 'workspaces' | 'all' = 'all'
) {
  return useMemo(() => {
    const noFilter: ISchedule[] = data ?? [];
    return noFilter
      .filter((x) =>
        filter === 'all'
          ? true
          : (filter === 'rooms' && x.resource.type === 'meeting_room') ||
            (filter === 'workspaces' && x.resource.type !== 'meeting_room')
      )
      .sort(sortSchedulesByStart);
  }, [filter, data]);
}
