import { Office } from '@gettactic/api';
import { getCustomOfficeDashboardMessage } from '@/lib/featureFlags';

export function CustomOfficeDashboardBanner({ office }: { office: Office }) {
  return (
    <div>
      <div className="mt-5 flex w-full flex-col items-center justify-around rounded-lg bg-red-200 py-3 sm:flex-row">
        <h4 className="max-w-md text-center text-lg text-red-600 sm:text-left">
          {getCustomOfficeDashboardMessage(office.id)}
        </h4>
      </div>
    </div>
  );
}
