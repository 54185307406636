import { classNames } from '@/lib/classNames';
import { Drawer } from '@mantine/core';
import React, { useCallback } from 'react';

type SideDrawerProps = React.PropsWithChildren<{
  maxWidthStyle?: string;
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  title?: string;
  onClose?: () => void;
  size?: number;
}>;

export const SideDrawer = ({
  maxWidthStyle,
  open,
  setOpen,
  title,
  onClose,
  size,
  children
}: SideDrawerProps): JSX.Element => {
  const onDrawerClose = () => {
    setOpen(false);
    onClose?.();
  };

  return (
    <Drawer
      opened={open}
      onClose={onDrawerClose}
      size={size ? size : 600}
      title={title}
      withCloseButton={!!title}
      position="right"
      overlayProps={{
        color: 'var(--color-overlay)'
      }}
      classNames={{
        title:
          'text-xl font-bold leading-relaxed text-secondary sm:text-2xl sm:leading-snug',
        header: 'px-4 py-2'
      }}
    >
      <div className="fixed inset-y-0 top-10 right-0 flex w-full">
        <div
          className={classNames(
            'flex h-full flex-1 flex-col bg-white p-4',
            maxWidthStyle
          )}
        >
          {children}
        </div>
      </div>
    </Drawer>
  );
};
