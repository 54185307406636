import { Tooltip } from '@/components/basic';
import CheckedInTeamMembersBeta2 from '@/components/partial/CheckedInTeamMembersBeta2';
import { Paper } from '@mantine/core';

type Props = {
  weekDate: string;
};
export function DashboardTeams({ weekDate }: Props) {
  return (
    <Paper
      shadow="md"
      className="order-last col-span-2 rounded-lg border border-gray-200 bg-white px-4 py-5 sm:px-6 md:order-none md:col-span-1"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <h3 className="font-medium text-gray-900 text-lg leading-6">
            Team Schedules
          </h3>
          <Tooltip>
            Filter to see who will be in the office, who will be working from
            home, and who's out of office. Use the week-to-week arrows above to
            change the dates.
          </Tooltip>
        </div>
      </div>
      <CheckedInTeamMembersBeta2 weekDate={weekDate} />
    </Paper>
  );
}
