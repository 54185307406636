import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { usePopper } from 'react-popper';
import { ITeam, IUserFull } from '@gettactic/api';
import { api } from '@/lib/api/api';
import useTeamMembers from '@/hooks/useTeamMembers';
import useTeams from '@/hooks/useTeams';
import { useUserTeamsList } from '@/hooks/useUserTeams';

export function useDefaultGroupMembers() {
  const queryClient = useQueryClient();
  const { teamList: groups } = useTeams({ type: 'group', hidden: false });
  const { users: groupMembers } = useTeamMembers(
    groups?.elements[0]?.id ?? null,
    null,
    'group'
  );
  const mutateDefaultGroupMembers = async () => {
    queryClient.invalidateQueries(['organizations.teamsWithOptions']);
    queryClient.invalidateQueries(['teams.members']);
  };
  return {
    defaultGroupMembers: groupMembers,
    defaultMutateGroupMembers: mutateDefaultGroupMembers
  };
}

export default function useFavs(user: IUserFull) {
  const queryClient = useQueryClient();
  const { userTeamList: userGroups } = useUserTeamsList(user.id, 'group');
  const { teamList: groups } = useTeams({ type: 'group', hidden: false });
  const { defaultGroupMembers, defaultMutateGroupMembers } =
    useDefaultGroupMembers();

  const mutateAll = async () => {
    queryClient.invalidateQueries(['teams.forUser']);
    queryClient.invalidateQueries(['organizations.teamsWithOptions']);
    await defaultMutateGroupMembers();
  };
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }]
  });

  const userHasAnyFav =
    !!userGroups && userGroups.elements && userGroups.elements.length > 0;
  const isUserInDefaultGroup =
    !!defaultGroupMembers &&
    !!defaultGroupMembers.elements.find((x) => x.id === user.id);
  const [hasAnyFav, setHasAnyFav] = useState(userHasAnyFav);

  useEffect(() => {
    setHasAnyFav(userHasAnyFav);
  }, [userHasAnyFav]);

  const addUserToDefaultGroup = async () => {
    if (!isUserInDefaultGroup) {
      const defaultGroup = groups?.elements.length
        ? groups.elements[0]
        : (
            await api.client.teams.add({
              name: `My Favorites`,
              parent_id: null,
              type: 'group'
            })
          )?.result;
      if (!defaultGroup) {
        throw new Error(`Sorry we couldn't save your user`);
      }
      await api.client.teams.addMember({
        teamId: defaultGroup.id,
        userId: user.id,
        type: 'group'
      });
    }
    setHasAnyFav(true);
    await mutateAll();
    toast.success(`Added to your favorites!`);
  };
  const removeUserFromDefaultGroup = async () => {
    if (!groups) {
      return;
    }
    if (isUserInDefaultGroup) {
      const defaultGroup =
        groups && groups.elements && groups.elements.length > 0
          ? groups.elements[0]
          : null;
      if (!defaultGroup) {
        throw new Error(`Error trying to get the default Group`);
      }
      toast.success(`Removed from your favorites!`);
      await api.client.teams.removeMember({
        teamId: defaultGroup.id,
        userId: user.id,
        type: 'group'
      });
    }
    await mutateAll();
  };

  const addUserToGroup = async (group: ITeam) => {
    if (!groups) {
      return;
    }
    await api.client.teams.addMember({
      teamId: group.id,
      userId: user.id,
      type: 'group'
    });
    setHasAnyFav(true);
    await mutateAll();
    toast.success(`Added to your favorites!`);
  };
  const removeUserFromGroup = async (group: ITeam) => {
    if (!groups) {
      return;
    }
    await api.client.teams.removeMember({
      teamId: group.id,
      userId: user.id,
      type: 'group'
    });
    await mutateAll();
    toast.success(`Removed from your favorites!`);
  };

  return {
    open,
    setOpen,
    styles,
    attributes,
    arrowElement,
    setArrowElement,
    popperElement,
    setPopperElement,
    referenceElement,
    setReferenceElement,
    hasAnyFav,
    removeUserFromDefaultGroup,
    addUserToDefaultGroup,
    addUserToGroup,
    removeUserFromGroup,
    groups,
    userGroups
  };
}
