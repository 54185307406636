import Link from 'next/link';
import React, { Dispatch, SetStateAction, useCallback, useRef } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { Portal } from 'reakit/Portal';
import { ITeam, ResGetTeams } from '@gettactic/api';
import { classNames } from '@/lib/classNames';
import { Input } from '@/components/basic';

type Props = {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  hasAnyFav: boolean;
  setPopperElement: Dispatch<SetStateAction<HTMLElement | null>>;
  setArrowElement: Dispatch<SetStateAction<HTMLElement | null>>;
  styles: { [p: string]: React.CSSProperties };
  attributes: { [p: string]: { [p: string]: string } | undefined };
  referenceElement: HTMLElement | null;
  addUserToDefaultGroup: () => void;
  removeUserFromDefaultGroup: () => void;
  addUserToGroup: (p: ITeam) => void;
  removeUserFromGroup: (p: ITeam) => void;
  groups: undefined | ResGetTeams;
  userGroups: undefined | ResGetTeams;
};
export function FavsDetails({
  setOpen,
  setPopperElement,
  setArrowElement,
  open,
  styles,
  attributes,
  referenceElement,
  hasAnyFav,
  addUserToDefaultGroup,
  removeUserFromDefaultGroup,
  addUserToGroup,
  removeUserFromGroup,
  groups,
  userGroups
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const refToOpen = useRef(referenceElement);
  refToOpen.current = referenceElement;
  const close = useCallback(() => {
    setOpen(false);
  }, []);
  useOnclickOutside(close, {
    refs: [ref, refToOpen]
  });
  return (
    <Portal>
      <div
        className={classNames(!open ? 'hidden' : '')}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <div
          className={classNames(
            !open ? 'hidden opacity-0' : ' opacity-1 m-2 bg-white',
            'rounded-sm border bg-white shadow-md transition duration-300 ease-in-out'
          )}
        >
          <div
            ref={setArrowElement}
            style={styles.arrow}
            {...attributes.arrow}
          ></div>
          <div className="mx-2 my-2" ref={ref}>
            <h4 className="border-b border-gray-200 text-base leading-relaxed text-gray-900">
              Add to...
            </h4>
            <ul className="mt-3">
              {!groups || groups.elements.length === 0 ? (
                <li className="flex items-center space-x-2 text-sm">
                  <Input
                    type="checkbox"
                    checked={hasAnyFav}
                    className="border border-tertiary shadow-none"
                    onChange={(ev) => {
                      if (hasAnyFav) {
                        removeUserFromDefaultGroup();
                      } else {
                        addUserToDefaultGroup();
                      }
                    }}
                  />
                  <span>My Favorites</span>
                </li>
              ) : (
                groups.elements.map((group) => {
                  const isInGroup =
                    !!userGroups &&
                    !!userGroups.elements.find((x) => x.id === group.id);
                  return (
                    <li
                      key={group.id}
                      className="flex items-center space-x-2 text-sm"
                    >
                      <Input
                        type="checkbox"
                        checked={isInGroup}
                        className="mt-1 border border-tertiary shadow-none"
                        onChange={async (ev) => {
                          if (isInGroup) {
                            await removeUserFromGroup(group);
                          } else {
                            await addUserToGroup(group);
                          }
                        }}
                      />
                      <span>{group.name}</span>
                    </li>
                  );
                })
              )}
            </ul>

            <div className="mt-4">
              <Link href="/settings/favorites">
                <a className="text-sm text-secondary underline">
                  Visit My Favorites Lists
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}
