import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button, Modal } from '@/components/basic';

export type CropInfo = {
  aspect?: number | null;
  x: number;
  y: number;
  width: number;
  height: number;
  unit: '%';
};

type Props = {
  aspect?: number | null;
  src: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  confirmCrop: (
    crop: CropInfo | null,
    size: { w: number; h: number } | null
  ) => void;
};

export function TacticCropModal({
  src,
  open,
  setOpen,
  confirmCrop,
  aspect
}: Props) {
  const ref = useRef<HTMLImageElement | null>(null);
  const [cropKey, setCropKey] = useState('0x0');
  const [crop, setCrop] = useState<Partial<Crop>>({
    aspect: !aspect ? undefined : aspect
  });
  const cancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const setCropInfo = useCallback((_: any, percentCrop: Crop) => {
    setCrop(percentCrop);
  }, []);

  useEffect(() => {
    const resize = () =>
      setCropKey(`${window.outerWidth}x${window.outerHeight}`);
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [crop]);

  return (
    <Modal opened={open} onClose={cancel}>
      <div>
        <div className="p-4">
          <ReactCrop
            key={cropKey}
            onImageLoaded={(img) => {
              ref.current = img;
              setCrop({ aspect: aspect ? aspect : undefined });
              return false;
            }}
            src={src}
            crop={crop}
            ruleOfThirds
            onChange={setCropInfo}
          />
        </div>
      </div>
      <Modal.Footer>
        <Button
          onClick={cancel}
          className="w-full sm:w-auto"
          layout="squared"
          variant="white"
          font="boldGray"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (ref.current) {
              confirmCrop(null, {
                w: ref.current.naturalWidth,
                h: ref.current.naturalHeight
              });
            } else {
              confirmCrop(null, null);
            }
            setOpen(false);
          }}
          className="mt-4 w-full sm:mt-0 sm:ml-3 sm:w-auto"
          layout="squared"
          variant="primary"
          type="submit"
        >
          Skip crop
        </Button>
        <Button
          onClick={() => {
            if (ref.current) {
              confirmCrop(
                crop.width && crop.height ? (crop as CropInfo) : null,
                {
                  w: ref.current.naturalWidth,
                  h: ref.current.naturalHeight
                }
              );
            }
            setOpen(false);
          }}
          className="mt-4 w-full sm:mt-0 sm:ml-3 sm:w-auto"
          layout="squared"
          variant="primary"
          type="submit"
        >
          Crop
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
