import { OOORequest } from '@/components/attendance/employee/OOORequest';
import { ConfirmDialog } from '@/components/basic/ConfirmDialog/ConfirmDialog';
import { DatePickerDashboard } from '@/components/dashboard/DatePickerDashboard';
import { WorkingLocationDatePills } from '@/components/dashboard/WorkingLocationDatePills';
import { DashboardReservations } from '@/components/dashboard/new-dashboard/DashboardReservations';
import { DashboardReservationsByType } from '@/components/dashboard/new-dashboard/DashboardReservationsByType';
import { DashboardTeams } from '@/components/dashboard/new-dashboard/DashboardTeams';
import NewUserWelcomeModalBeta2 from '@/components/partial/Modals/NewUserWelcomeModalBeta2';
import { ReserveModal } from '@/components/reserve/Reserve/ReserveModal';
import LoadingSpinner from '@/components/util/LoadingSpinner';
import {
  useAllApplicablePolicies,
  useApplicableRemotePolicy
} from '@/hooks/useApplicablePolicies';
import { useAttendanceRequestsWeek } from '@/hooks/useAttendanceRequests';
import { api } from '@/lib/api/api';
import { useAuthenticated } from '@/lib/api/appUser';
import {
  isHpe,
  isWorkingLocationsWithOOOFeaturesEnabled,
  orgRequiresReservationAfterPickingAnOffice
} from '@/lib/featureFlags';
import { isWorkingLocationOffice, parseApiDate } from '@/lib/utils';
import {
  IWorkingLocation,
  IWorkingLocationPresenceType,
  LeavePolicyType,
  Office,
  ResourceType,
  ScheduleParamsAll,
  WL_PRESENCE_OFFICE,
  WL_PRESENCE_OOO,
  WL_PRESENCE_REMOTE
} from '@gettactic/api';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { addDays, format, parse, startOfWeek } from 'date-fns';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';

export default function NewDashboard() {
  const [oooRequest, setOooRequest] = useState<{
    date: string;
    type: 'ooo' | 'remote';
  } | null>(null);
  const [onConfirmDialogCallback, setOnConfirmDialogCallback] = useState<
    (() => void) | null
  >(null);
  const {
    userContext: { currentOffice, authenticatedUser, deviceTz }
  } = useAuthenticated();
  const [reserveModalData, setReserveModalData] = useState<{
    start: Date;

    resourceType: ResourceType;
    office: Office | undefined;
  } | null>(null);
  const [weekDate, setWeekDate] = useState<string>(() =>
    format(new Date(), 'yyyy-MM-dd')
  );

  const commonParams: ScheduleParamsAll = {
    date: weekDate,
    weeks: 1,
    type: ['desk', 'workspace', 'meeting_room', 'parking_space', 'parking_lot'],
    tz: currentOffice.time_zone
  };
  const parsedDate = parse(weekDate, 'yyyy-MM-dd', new Date());
  const firstMondayBefore = startOfWeek(parsedDate, { weekStartsOn: 1 });
  const firstMondayBeforeStr = format(firstMondayBefore, 'yyyy-MM-dd');
  const endDateForWeekStr = format(addDays(firstMondayBefore, 7), 'yyyy-MM-dd');
  const workingLocationParams = {
    date: firstMondayBeforeStr,
    weeks: 1,
    user: [authenticatedUser.user?.id ?? '']
  };
  const remotePolicy = useApplicableRemotePolicy(
    { tz: deviceTz, start: firstMondayBeforeStr, end: endDateForWeekStr },
    { enabled: !!isWorkingLocationsWithOOOFeaturesEnabled(authenticatedUser) }
  );
  const { query: allPolicies, invalidate } = useAllApplicablePolicies(
    { tz: deviceTz, start: firstMondayBeforeStr, end: endDateForWeekStr },
    { enabled: !!isWorkingLocationsWithOOOFeaturesEnabled(authenticatedUser) }
  );

  const workingLocationData = useQuery(
    ['schedules', 'workingLocations', workingLocationParams],
    async () => {
      const res = await api.client.schedules.workingLocations(
        workingLocationParams
      );
      return res.result;
    },
    {
      keepPreviousData: true,
      enabled: !!currentOffice.id
    }
  );

  const { attendanceByDay } = useAttendanceRequestsWeek(
    firstMondayBefore,
    deviceTz
  );

  const queryClient = useQueryClient();
  const invalidateQueries = useCallback(async () => {
    await invalidate();
    await queryClient.invalidateQueries(['schedules']);
    await queryClient.invalidateQueries(['attendanceRequests']);
    await queryClient.invalidateQueries(['leavePolicies']);
  }, []);

  const openReserveModal = (date: string, office: Office | undefined) => {
    setReserveModalData({
      start: parseApiDate(date),
      resourceType: 'desk',
      office: office ?? undefined
    });
  };

  const updateWorkingLocation = async (
    workingLocationId: IWorkingLocationPresenceType,
    date: string,
    dayNumber: number,
    currentWorkingLocation: IWorkingLocation | null
  ) => {
    const isOffice = isWorkingLocationOffice(workingLocationId);
    const location = isOffice
      ? {
          office_id: workingLocationId,
          location: WL_PRESENCE_OFFICE as IWorkingLocationPresenceType
        }
      : {
          location: workingLocationId
        };

    const resource = currentWorkingLocation?.resource ?? null;
    const reservations = resource
      ? (scheduleMeData.data?.elements ?? []).filter(
          (x) =>
            x.resource.id === resource.id && x.slots[0].start.startsWith(date)
        )
      : [];
    const isChangingOffice =
      workingLocationId !== currentWorkingLocation?.location &&
      workingLocationId !== resource?.office_id;

    const updateWorkingLocationCallback = async () => {
      const hasPolicies =
        (allPolicies.data ?? []).filter(
          (x) => x.policy.policy_type !== WL_PRESENCE_REMOTE
        ).length > 0;
      if (
        workingLocationId === WL_PRESENCE_OOO &&
        hasPolicies &&
        isWorkingLocationsWithOOOFeaturesEnabled(authenticatedUser)
      ) {
        setOooRequest({ date, type: 'ooo' });
      } else if (
        workingLocationId === WL_PRESENCE_REMOTE &&
        remotePolicy &&
        remotePolicy.policy.is_approvable
      ) {
        setOooRequest({ date, type: 'remote' });
      } else if (
        workingLocationId === WL_PRESENCE_REMOTE &&
        remotePolicy &&
        !remotePolicy.policy.is_approvable
      ) {
        // we should silently send the Remote Request because is not approvable
        await api.client.leave.createRequest({
          policy_type: remotePolicy.policy.policy_type as LeavePolicyType,
          reason: '',
          starts: `${format(parseApiDate(date), 'yyyy-MM-dd')}T00:00:00.000Z`,
          ends: `${format(parseApiDate(date), 'yyyy-MM-dd')}T23:59:59.000Z`,
          time_zone_id: deviceTz
        });
        await invalidateQueries();
      } else {
        await api.client.schedules.updateWorkingLocationDay(
          location,
          dayNumber,
          date
        );
        await invalidateQueries();
      }
      await queryClient.invalidateQueries(['schedules']);
      if (
        isOffice &&
        orgRequiresReservationAfterPickingAnOffice(authenticatedUser) &&
        isChangingOffice
      ) {
        const targetOffice = (authenticatedUser.offices?.offices ?? []).find(
          (x) => x.id === workingLocationId
        );
        openReserveModal(date, targetOffice);
      }
    };

    if (reservations.length && isChangingOffice) {
      setOnConfirmDialogCallback(() => async () => {
        try {
          const deleteAll = reservations.map((x) =>
            api.client.schedules.delete({ schedule: x.id, recurring: false })
          );
          await Promise.all(deleteAll);
        } catch (e) {
          toast.error(
            "Sorry we couldn't delete your reservations. Please try again."
          );
        }
        await updateWorkingLocationCallback();
      });
    } else {
      await updateWorkingLocationCallback();
    }
  };

  const workingLocationProfile = useQuery(
    ['working-locations-profile', authenticatedUser.user?.id],
    async () => {
      const res = await api.client.schedules.workingLocationsDefaults(
        authenticatedUser.user?.id ?? ''
      );
      return res.result;
    },
    {
      keepPreviousData: true,
      enabled: true
    }
  );

  // TODO: It would be really nice to get office_name, floor_name/floor_id, area_name/area_id, and subarea_name/subarea_id in the elements
  // object of this query so that we can interpolate the data in the card without doing additional lookups.
  const scheduleMeData = useQuery(
    ['schedules', 'me', commonParams],
    async () => {
      const res = await api.client.schedules.allIndexed('me', commonParams);
      return res.result;
    },
    {
      keepPreviousData: true,
      enabled: !!currentOffice.id
    }
  );

  return (
    <>
      {!workingLocationProfile.data ? (
        <div className="flex h-full items-center justify-center text-secondary md:overflow-x-auto">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="flex h-full w-full flex-col gap-8 p-4 lg:overflow-y-auto">
          {workingLocationProfile.data ? (
            <NewUserWelcomeModalBeta2
              authenticatedUser={authenticatedUser}
              workingLocationProfileData={workingLocationProfile.data}
            />
          ) : null}
          <div className="flex w-full flex-col gap-4">
            {scheduleMeData.isSuccess && scheduleMeData.data ? (
              <div className="flex items-center gap-2">
                <DatePickerDashboard date={weekDate} setDate={setWeekDate} />
                <h2 className="ml-2 font-bold text-3xl text-tertiary md:min-w-[230px]">
                  {format(parseApiDate(weekDate), 'MMMM')}
                </h2>
                {scheduleMeData?.data && scheduleMeData.isSuccess ? (
                  <div className="ml-4 flex gap-2">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-full border border-transparent bg-white p-2 drop-shadow-[0_1px_3px_rgba(0,0,0,0.25)] hover:bg-primary hover:text-primary-text focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                      onClick={() =>
                        setWeekDate(scheduleMeData.data!.previous_week_date)
                      }
                    >
                      <ChevronLeftIcon className="h-4 w-4" />
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center rounded-full border border-transparent bg-white p-2 drop-shadow-[0_1px_3px_rgba(0,0,0,0.25)] hover:bg-primary hover:text-primary-text focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                      onClick={() =>
                        setWeekDate(scheduleMeData.data!.next_week_date)
                      }
                    >
                      <ChevronRightIcon className="h-4 w-4" />
                    </button>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="h-[45px] w-1/2 animate-pulse rounded-md bg-gray-300" />
            )}
            <div className="flex w-full">
              {workingLocationData.isSuccess &&
              scheduleMeData.isSuccess &&
              scheduleMeData?.data &&
              workingLocationData?.data ? (
                <WorkingLocationDatePills
                  workingLocationData={workingLocationData.data}
                  attendanceByDay={attendanceByDay}
                  scheduleMeData={scheduleMeData.data}
                  updateWorkingLocation={updateWorkingLocation}
                  openReserveModal={openReserveModal}
                />
              ) : null}
            </div>
          </div>

          {!isHpe(authenticatedUser) ? (
            <div className="grid h-full gap-4 md:grid-cols-2">
              <DashboardTeams weekDate={weekDate} />
              <DashboardReservations scheduleMeData={scheduleMeData} />
            </div>
          ) : (
            <div className="">
              <div className="grid h-full gap-4 md:grid-cols-2">
                <DashboardReservationsByType
                  type={'workspaces'}
                  scheduleMeData={scheduleMeData}
                  showQuestionnaireAlert={false}
                />
                <DashboardReservationsByType
                  type={'rooms'}
                  scheduleMeData={scheduleMeData}
                  showQuestionnaireAlert={true}
                />
              </div>
            </div>
          )}
          {reserveModalData ? (
            <ReserveModal
              reserveModal={!!reserveModalData}
              embedded={false}
              onClose={() => setReserveModalData(null)}
              start={reserveModalData.start}
              resourceType={reserveModalData.resourceType}
              office={reserveModalData.office}
            />
          ) : null}
          {oooRequest ? (
            <OOORequest
              onClose={() => setOooRequest(null)}
              request={oooRequest}
            />
          ) : null}
          {onConfirmDialogCallback ? (
            <ConfirmDialog
              type="confirm"
              onConfirm={onConfirmDialogCallback}
              title="Do you want to cancel?"
              onClose={() => setOnConfirmDialogCallback(null)}
              open={!!onConfirmDialogCallback}
            >
              <p className="text-gray-500 text-sm">
                If you confirm the current reservations on this day will be
                cancelled
              </p>
            </ConfirmDialog>
          ) : null}
        </div>
      )}
    </>
  );
}
