import { useQuery } from '@tanstack/react-query';
import { addDays, eachDayOfInterval, format } from 'date-fns';
import { useMemo } from 'react';
import {
  ALL_LEAVE_POLICY_TYPES,
  IAttendanceRequestType,
  LeavePolicyType,
  LeaveRequestsParams,
  parseDateOnlyString
} from '@gettactic/api';
import { api } from '@/lib/api/api';

export type QueryOptions =
  | { keepPreviousData?: boolean; enabled?: boolean }
  | undefined;
export type IAttendanceRequestTypeWithTitle = IAttendanceRequestType & {
  title?: string;
};

export function useAttendanceRequests(
  params: Partial<Omit<LeaveRequestsParams, 'start' | 'end'>> & {
    start: Date;
    end: Date;
    tz: string;
  },
  options: QueryOptions = { keepPreviousData: true, enabled: true }
) {
  const commonParams: LeaveRequestsParams = {
    ...params,
    start: params.start ? format(params.start, 'yyyy-MM-dd') : undefined,
    end: params.end ? format(params.end, 'yyyy-MM-dd') : undefined,
    workPolicyType: params.workPolicyType ? params.workPolicyType : [],
    status: params.status ? params.status : ['pending', 'accepted']
  };

  // Query the applicable policies to get custom policy titles/descriptions, not just the policy type
  // to provide context on requests. This is a bit of a hack, but it works.
  const type = [...ALL_LEAVE_POLICY_TYPES];
  const policyParams: { start?: string; tz: string; type?: LeavePolicyType[] } =
    {
      type,
      tz: params.tz
    };

  const dataQuery = useQuery(
    ['attendanceRequests', commonParams],
    async () => {
      const res = await api.client.leave.allRequests(commonParams);
      return res.result ? res.result.elements : [];
    },
    options
  );

  const policiesQuery = useQuery(
    ['leavePolicies', params],
    async () => {
      const res = await api.client.leave.allApplicablePolicies(policyParams);
      return res;
    },
    {
      keepPreviousData: true,
      onError: (err) => {
        console.error(err);
      }
    }
  );

  const dataWithTitles: IAttendanceRequestTypeWithTitle[] =
    dataQuery.data?.map((data) => {
      const matchingPolicy = policiesQuery.data?.find(
        (policy) => policy.policy.policy_type === data.policy_type
      );
      return {
        ...data,
        title: matchingPolicy?.policy.title
      };
    }) ?? [];

  return { ...dataQuery, data: dataWithTitles };
}

export function useAttendanceRequestsWeek(monday: Date, tz: string) {
  const attendanceQuery = useAttendanceRequests({
    start: monday,
    end: addDays(monday, 7),
    tz
  });

  const attendanceByDay = useMemo(() => {
    const requests = attendanceQuery.data ?? [];
    const weekDayRequests: { [date: string]: IAttendanceRequestType } = {};
    requests.forEach((request) => {
      eachDayOfInterval({
        start: parseDateOnlyString(request.starts),
        end: parseDateOnlyString(request.ends)
      }).forEach((date) => {
        // Replace only if the request is newer
        const current = weekDayRequests[format(date, 'yyyy-MM-dd')];
        // we are using the `starts` fields, but we should be using smth like `update`
        if (!current || current.starts > request.starts) {
          weekDayRequests[format(date, 'yyyy-MM-dd')] = request;
        }
      });
    });
    return weekDayRequests;
  }, [attendanceQuery.data]);
  return { attendanceQuery, attendanceByDay };
}
