import { CropInfo } from '@/components/upload/TacticCropModal';
import S3 from 'aws-sdk/clients/s3';

export function getFileContents(
  file: File,
  format: 'ArrayBuffer' | 'base64' = 'ArrayBuffer'
): Promise<ArrayBuffer | string | undefined> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (readEvent) => {
      if (format === 'ArrayBuffer') {
        resolve(
          readEvent.target
            ? (readEvent.target.result as ArrayBuffer)
            : undefined
        );
      } else if (format === 'base64') {
        resolve(
          readEvent.target ? (readEvent.target.result as string) : undefined
        );
      } else {
        throw new Error(`Invalid format ${format}`);
      }
    };

    format === 'ArrayBuffer'
      ? reader.readAsArrayBuffer(file)
      : reader.readAsDataURL(file);
  });
}

export async function doLocalUpload(
  file: File,
  apiEndPoint: string,
  extraParams = {}
) {
  const searchParams = new URLSearchParams({
    filename: file.name,
    ...extraParams
  }).toString();
  const formData = new FormData();
  formData.append('svg', file);
  const resApi = await fetch(`${apiEndPoint}?${searchParams}`, {
    method: 'POST',
    body: formData
  });
  const res = await resApi.json();
  return res;
}

export async function doUpload(
  file: File,
  apiEndPoint: string,
  extraParams = {}
) {
  const searchParams = new URLSearchParams({
    filename: file.name,
    ...extraParams
  }).toString();
  const resApi = await fetch(`${apiEndPoint}?${searchParams}`);
  const data = (await resApi.json()) as {
    error?: string;
    token: {
      Credentials: {
        AccessKeyId: string;
        SecretAccessKey: string;
        SessionToken: string;
      };
    };
    region: string;
    bucket: string;
    key: string;
  };
  if (data.error) {
    throw new Error(`Api upload error: ${data.error}`);
  }
  const s3 = new S3({
    accessKeyId: data.token.Credentials.AccessKeyId,
    secretAccessKey: data.token.Credentials.SecretAccessKey,
    sessionToken: data.token.Credentials.SessionToken,
    region: data.region
  });
  const blob = await getFileContents(file);
  const params = {
    ACL: 'public-read',
    Bucket: data.bucket,
    Key: data.key,
    Body: blob,
    CacheControl: 'max-age=630720000, public',
    ContentType: file.type
  };

  const signedURL = new URL(s3.getSignedUrl('putObject', params));
  // GRRRRRR @TODO NOT WORKING YET!
  // const uploadURL = signedURL.toString().replace(signedURL.hostname, 'cdn.gettactic.com');
  const res = await fetch(signedURL.toString(), {
    method: 'PUT',
    body: file
  });
  return data;
}

export function getCropUrl(
  crop: CropInfo,
  imageNaturalSize: { w: number; h: number },
  imageResize: { w: number; h: number } | null,
  fullUrl: string
) {
  const left = Math.round((crop.x * imageNaturalSize.w) / 100);
  const top = Math.round((crop.y * imageNaturalSize.h) / 100);
  const bottom = Math.round(
    ((100 - crop.y - crop.height) * imageNaturalSize.h) / 100
  );
  const right = Math.round(
    ((100 - crop.x - crop.width) * imageNaturalSize.w) / 100
  );
  const width = Math.round((crop?.width * imageNaturalSize.w) / 100);
  const height = Math.round((crop?.height * imageNaturalSize.h) / 100);
  const trim = [top, right, bottom, left].join(',');
  const cropInfo = `?${new URLSearchParams({
    width: imageResize ? imageResize.w.toString() : width.toString(),
    height: imageResize ? imageResize.h.toString() : height.toString(),
    trim,
    image: fullUrl
  }).toString()}`;
  return `https://cdn.gettactic.com/image-crop/${cropInfo}`;
}

export function getCroppedImg(
  image: HTMLImageElement,
  crop: { x: number; y: number; width: number; height: number }
) {
  const canvas = document.createElement('canvas') as HTMLCanvasElement;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error(`Impossible create canvas`);
  }
  const pixelRatio = window.devicePixelRatio;
  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject('Impossible convert to blob');
          return;
        }
        //blob.name = fileName;
        resolve(blob);
      },
      'image/jpeg',
      1
    );
  });
}
