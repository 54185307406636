import { useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '@/lib/api/api';
import { CalendarSource } from '@gettactic/api';

export function useAuthorizedIntegrations() {
  const {
    data: authorizedIntegrations,
    error,
    isLoading
  } = useQuery(['calendar.getAuthorizedIntegrations'], async () => {
    const isGoogleAuthorized = await api.client.calendar.hasAuthorization(
      CalendarSource.Google
    );
    const isMicrosoftAuthorized = await api.client.calendar.hasAuthorization(
      CalendarSource.Microsoft
    );
    return { isGoogleAuthorized, isMicrosoftAuthorized };
  });
  const loadingAuthorizations = isLoading;
  return { authorizedIntegrations, loadingAuthorizations, error };
}

export function useAuthorizationUrl(
  source: CalendarSource,
  organizationId: string
) {
  return api.client.calendar.getAuthorizationUrl(source, organizationId);
}

export function useCalendarByResource(resourceId: string) {
  const {
    data: calendar,
    error,
    isLoading
  } = useQuery(
    ['integrations.calendar', resourceId],
    async () => {
      const res = await api.client.calendar.getCalendarByResourceId(
        resourceId as string
      );
      return res.result;
    },
    {
      onError: (error) => {
        return {};
      },
      meta: {
        shouldIgnoreGlobalErrorHandler: true
      }
    }
  );
  return { calendar, isLoading, error };
}
