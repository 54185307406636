import React from 'react';
import {
  generateTimeZoneSelectOptions,
  findTimeZoneSelectOption,
  findTimeZoneFromId
} from '@/lib/utils';
import { Responsive } from '@/components/basic';
import useTimeZones from '@/hooks/useTimeZones';

const TimeZonePicker = React.forwardRef((props: any, ref) => {
  const { value, onChange, ...rest } = props;
  const timezones = useTimeZones();
  const selectedTimeZone = findTimeZoneFromId(value, timezones) || null;
  const timeZoneOptions = generateTimeZoneSelectOptions(timezones);
  const selectedTimeZoneOption = selectedTimeZone
    ? findTimeZoneSelectOption(selectedTimeZone, timeZoneOptions)
    : null;
  return (
    <Responsive.Select
      options={timeZoneOptions}
      value={selectedTimeZoneOption}
      ref={ref}
      onChange={(option: { value: string }) => {
        option && onChange(option.value);
      }}
      {...rest}
    />
  );
});

TimeZonePicker.displayName = 'TimeZonePicker';

export default TimeZonePicker;
