import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { api } from '@/lib/api/api';
import { classNames } from '@/lib/classNames';
import {
  USER_PHOTO_SIZES,
  UserPhotoCard
} from '@/components/partial/Cards/UserPhotoCard';

type Props = {
  dates: { start: Date; end: Date | null };
};

export function OOOOverlapWarning({ dates }: Props) {
  const [collapsed, setCollapsed] = useState(true);
  const formatted = useMemo(() => {
    return {
      start: format(dates.start, 'yyyy-MM-dd'),
      end: format(dates.end ?? dates.start, 'yyyy-MM-dd')
    };
  }, [dates.start, dates.end]);
  const overlapQuery = useQuery(['leaveOverlap', formatted], async () => {
    const res = await api.client.leave.conflicts(formatted);
    return res.result ? res.result.elements : [];
  });

  if (
    overlapQuery.isLoading ||
    !overlapQuery.isSuccess ||
    !overlapQuery.data ||
    overlapQuery.data.length === 0
  ) {
    return null;
  }
  const usersAll = overlapQuery.data.map((x) => x.user) ?? [];
  // remove duplicates
  const users = usersAll.filter(
    (v, i, a) => a.findIndex((t) => t.id === v.id) === i
  );
  const max = 6;
  const len = users.length;
  return (
    <div>
      <div className="flex items-center space-x-2">
        <ExclamationCircleIcon
          className="h-10 w-10 text-primary"
          aria-hidden="true"
        />
        <span className="leading-normal text-gray-500">
          The following team members have approved time off on one or more of
          the selected days
        </span>
      </div>
      <div
        className={classNames(
          'mb-5 ml-8 flex',
          collapsed ? '-space-x-4' : 'flex-wrap space-x-0'
        )}
      >
        {users.slice(0, collapsed ? max : len).map((user) => (
          <div
            title={user.name}
            key={user.id}
            className="h-8 w-8 rounded-full border-2 border-white dark:border-gray-800 "
          >
            <UserPhotoCard
              size={USER_PHOTO_SIZES.ROUNDED_FULL}
              profile_photo_url={user.profile_photo_url}
              name={user.name}
            />
          </div>
        ))}
        {len > max ? (
          <a
            onClick={() => setCollapsed(!collapsed)}
            className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-white bg-gray-700 text-xs font-medium text-white hover:bg-gray-600 dark:border-gray-800"
            href="#"
          >
            {collapsed ? (
              <>+{len - max}</>
            ) : (
              <>
                <ChevronLeftIcon className="h-4 w-4" />
              </>
            )}
          </a>
        ) : null}
      </div>
    </div>
  );
}
