import { useQuery } from '@tanstack/react-query';
import { ITeamType } from '@gettactic/api';
import { api } from '@/lib/api/api';

export default function useTeamMembers(
  teamId: string | null,
  query: string | null = null,
  type: ITeamType
) {
  const {
    data: users,
    error,
    isLoading
  } = useQuery(
    ['teams.members', type, teamId, query],
    async () => {
      const res = await api.client.teams.members({
        id: teamId ?? '',
        query,
        type
      });
      return res.result;
    },
    {
      enabled: !!teamId
    }
  );
  const loading = !users && !error;
  return { users, error, loading, isLoading };
}
