import Link from 'next/link';
import { useHasPendingDocuments } from '@/hooks/useDocument';

export function BannerPendingDocument() {
  const { hasPending } = useHasPendingDocuments();
  if (!hasPending) {
    return null;
  }
  return (
    <div>
      <div className="mt-5 flex w-full flex-col items-center justify-around rounded-lg bg-gray-600 p-10 sm:flex-row">
        <h4 className="max-w-md text-center  text-xl text-white sm:text-left">
          You have pending health documents for review{' '}
        </h4>
        <Link href="/settings/health/admin">
          <a className="mt-5 rounded-full bg-gray-50 px-16 py-2.5 text-lg font-semibold text-gray-600 sm:mt-0  md:px-10">
            Review documents
          </a>
        </Link>
      </div>
    </div>
  );
}
