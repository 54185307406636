import { useUserListPaginated } from '@/hooks/useUserList';
import { api } from '@/lib/api/api';
import { IUserFull, UserPropertyType } from '@gettactic/api';
import { logError } from '@gettactic/helpers/src/errors/logError';
import { useDebouncedValue } from '@mantine/hooks';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

export default function useUserSettings(initialUser: IUserFull | null = null) {
  const [selectedUser, setSelectedUser] = useState<IUserFull | null>(
    initialUser
  );
  const [localSearchQuery, setLocalSearchQuery] = useState<string>('');
  const [debounced] = useDebouncedValue(localSearchQuery, 1000, {
    leading: true
  });

  const {
    allPages: userList,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = useUserListPaginated(debounced, 300);

  return {
    selectedUser,
    setSelectedUser,
    userQuery: localSearchQuery,
    setUserQuery: setLocalSearchQuery,
    userList,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  };
}

export function useAllUserSettings() {
  const {
    data: userSettings,
    isLoading,
    error
  } = useQuery(['user.settings'], async () => {
    const res = await api.client.userSettings.getAll();
    return res.result ? res.result.elements : [];
  });

  return {
    userSettings,
    loadingUserSettings: isLoading,
    error
  };
}

interface ISaveUserSettings {
  type: UserPropertyType;
  value: string;
}

export function useSaveUserSettings(queryClient: QueryClient) {
  const saveSettings = useMutation(
    async ({ type, value }: ISaveUserSettings) => {
      return await api.client.userSettings.createOrUpdate(type, value);
    },
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(['user.settings']);
      },
      onError: (error) => {
        logError('Error: ', 'Unable to save Filters Data.');
        logError(error);
      }
    }
  );

  return saveSettings;
}
