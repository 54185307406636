import { format } from 'date-fns';
import {
  IDocumentPartial,
  IHealth,
  ITestDocument,
  IUserFull,
  IVaccinationDocument,
  prettifyDateOnlyStringTemplate
} from '@gettactic/api';
import { LocalizationDateTimeDictionary } from '@/hooks/useI18n';
import { HealthAdminStateDispatch } from '@/components/health/admin/useHealthAdminState';

export function getVaccinationStatus(h: IHealth) {
  if (h.group !== null && h.group !== 'none') {
    return h.group;
  }
  if (!h.vaccination) {
    return 'not-vaccinated';
  }
  if (h.vaccination.boosted) {
    return 'boosted';
  }
  if (h.vaccination.completed) {
    return 'fully-vaccinated';
  }
  if (h.vaccination.started) {
    return 'partial-vaccinated';
  }
  return 'unknown';
}

export function getVaccinationStatusName(h: IHealth) {
  const status = getVaccinationStatus(h);
  switch (status) {
    case 'religious':
      return 'Exemption Religious';
    case 'immunity':
      return 'Exemption Immunity';
    case 'medical':
      return 'Exemption Medical';
    case 'policy':
      return 'Policy Immunity';
    case 'fully-vaccinated':
      return 'Fully Vaccinated';
    case 'not-vaccinated':
      return 'Unvaccinated';
    case 'partial-vaccinated':
      return 'Partially Vaccinated';
    case 'boosted':
      return 'Fully Vaccinated + Booster';
    case 'unknown':
      return '-';
    default:
      const exhaustiveCheck: never = status;
      throw new Error(`Unhandled resource type: ${exhaustiveCheck}`);
  }
}

export function serializeDocumentDate(d: Date | null) {
  return d ? `${format(d, 'yyyy-MM-dd')}T${format(d, 'HH:mm:ss')}.000Z` : '';
}

export function submitDate(
  doc: IDocumentPartial,
  templates: LocalizationDateTimeDictionary
) {
  // @todo: Probably we want to change to `created` but we don't have that date API exposed.
  const date =
    (doc.vaccination ? doc.vaccination.updated : doc.test?.updated) ?? '';
  return prettifyDateOnlyStringTemplate(
    date,
    templates.displayMonthDayYearWordy
  );
}

export function joinVaccineDoses(
  doc: IVaccinationDocument,
  templates: LocalizationDateTimeDictionary,
  showYear = false
) {
  return doc.dates
    .map((x, idx) =>
      prettifyDateOnlyStringTemplate(
        x,
        showYear
          ? templates.displayMonthDayYearWordy
          : templates.displayMonthDayWordy
      )
        ? `${doc.vaccines[idx]?.name ? `${doc.vaccines[idx]?.name}, ` : ''} ${prettifyDateOnlyStringTemplate(
            x,
            showYear
              ? templates.displayMonthDayYearWordy
              : templates.displayMonthDayWordy
          )}`
        : ''
    )
    .filter((x) => !!x)
    .join(' • ');
}

export function joinVaccines(doc: IVaccinationDocument) {
  return doc.dates
    .map(
      (x, idx) =>
        `${doc.vaccines[idx]?.name ? `${doc.vaccines[idx]?.name}` : ''}`
    )
    .filter((x) => !!x.length)
    .join(', ');
}

export function joinVaccinesDates(
  doc: IVaccinationDocument,
  templates: LocalizationDateTimeDictionary,
  showYear = false
) {
  return doc.dates
    .map((x, idx) =>
      prettifyDateOnlyStringTemplate(
        x,
        showYear
          ? templates.displayMonthDayYearWordy
          : templates.displayMonthDayWordy
      )
        ? `${prettifyDateOnlyStringTemplate(
            x,
            showYear
              ? templates.displayMonthDayYearWordy
              : templates.displayMonthDayWordy
          )}`
        : ''
    )
    .filter((x) => !!x)
    .join(' • ');
}

export function isVaccineDocument(
  doc: IVaccinationDocument | ITestDocument
): doc is IVaccinationDocument {
  return 'vaccines' in doc;
}

export function hasVaccineDocument(doc: IDocumentPartial): boolean {
  return doc.type === 'vaccination' && doc.vaccination !== null;
}

export function openDoc(
  doc: IDocumentPartial,
  dispatch: HealthAdminStateDispatch
) {
  dispatch({ type: 'change-open-document', payload: doc });
}

export function openUser(user: IUserFull, dispatch: HealthAdminStateDispatch) {
  dispatch({ type: 'change-open-user', payload: user });
}
